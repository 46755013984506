/* eslint-disable class-methods-use-this */
import {
  getModule,
  Action,
  Mutation,
  Module,
  VuexModule,
} from 'vuex-module-decorators'
import { store } from '@/store'
import axios from '@/libs/axios'

const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

@Module({
  store,
  dynamic: true,
  namespaced: true,
  name: CALENDAR_APP_STORE_MODULE_NAME,
})
export class CalendarStore extends VuexModule {
  calendarOptions = [
    {
      color: 'danger',
      label: 'Personal',
    },
    {
      color: 'primary',
      label: 'Business',
    },
    {
      color: 'warning',
      label: 'Family',
    },
    {
      color: 'success',
      label: 'Holiday',
    },
    {
      color: 'info',
      label: 'ETC',
    },
  ]

  selectedCalendars = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']

  @Mutation
  SET_SELECTED_EVENTS(val: string[]) {
    this.selectedCalendars = [...val]
  }

  @Action
  fetchEvents({ calendars }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/calendar/events', {
          params: {
            calendars: calendars.join(','),
          },
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  addEvent({ event }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post('/apps/calendar/events', { event })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  updateEvent({ event }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/apps/calendar/events/${event.id}`, { event })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  removeEvent({ id }: any) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/apps/calendar/events/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export const loadCalendarStore = () => {
  if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
    store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, CalendarStore)
  }

  return getModule(CalendarStore, store)
}

export const unloadCalendarStore = () => {
  if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
    store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
  }
}
